<template>
  <div
    :class="{
      'border border-gray-300': !borderLess
    }"
  >
    <div class="flex flex-col justify-center items-center">
      <div class="py-2 qrcode-item" ref="qrcode" />
      <h3 class="font-semibold text-blueGray-500 text-md -mt-2">
        <!-- {{ item[nameKey] }} -->
        <!-- {{ text }} -->
      </h3>
    </div>
  </div>
</template>

<script>
import * as QRCode from "easyqrcodejs";

export default {
  name: "QrCode",
  props: {
    item: {
      type: Object,
      require: true
    },
    borderLess: {
      type: Boolean,
      default: false
    },
    qronly: {
      type: Boolean,
      default: false
    },
    nameKey: {
      type: String,
      default: "name"
    }
  },
  computed: {
    text() {
      const { qr_id } = this.item;

      if (this.qronly) {
        return qr_id;
      }
      return `${process.env.VUE_APP_M_URL}/qr/${qr_id}`;
    }
  },
  methods: {
    createQRCode(el) {
      new QRCode(el, {
        text: this.text,
        logoBackgroundColor: "#fffff",
        logoBackgroundTransparent: true,
        title: this.item[this.nameKey],
        titleFont: "normal normal bold 14px Arial",
        titleColor: "#004284",
        titleBackgroundColor: "#fff",
        titleHeight: 35,
        titleTop: 25
      });
    }
  },
  mounted() {
    this.createQRCode(this.$refs.qrcode);
  }
};
</script>

<style>
@media print {
  .qr-code-box {
    display: block;
    text-align: center;
  }
}
</style>
